import React, { useEffect, useLayoutEffect, useState } from "react";
import { DtpButton, DtpModal } from "dtp-react-components";
import DtpAdmin from "components/DtpAdmin/DtpAdmin";
import DtpHeaderAdmin from "components/DtpHeaderAdmin/DtpHeaderAdmin";
import DtpMenuAdmin from "components/DtpMenuAdmin/DtpMenuAdmin";
import DtpContentAdmin from "components/DtpContentAdmin/DtpContentAdmin";

const AvisoCertificadoDigital = ({ avancar, voltar }) => {
  const [hideCloseButton, setHideCloseButton] = useState(false);

  useEffect(() => {
    setHideCloseButton(true);
  }, []);

  useLayoutEffect(() => {
    document
      .querySelectorAll(".title-container button")
      .forEach((e) => e.remove());
  }, [hideCloseButton]);

  return (
    <>
      <div className="selecao-abrangencia">
        <DtpAdmin close={true}>
          <DtpHeaderAdmin
            key="headerAdmin"
            appInfo={{
              firstName: "Portal de Atendimento | INSS",
              lastName: "",
              owner: "11.11.2022",
            }}
            doLogout={voltar}
            mudarAbrangencia={() => null}
          ></DtpHeaderAdmin>
          <DtpMenuAdmin
            key="menuAdmin"
            version={"_"}
            userData={{
              firstName: "",
              document: {
                label: "",
                number: "",
              },
              information: "",
              roles: [],
            }}
          />
          <DtpContentAdmin key="contentAdmin">
            <DtpModal
              id={"modal"}
              show={true}
              title={"LOGIN - PAT Entidade Conveniada"}
              showTitle={true}
              onHide={() => null}
              size={"md"}
            >
              <DtpModal.DtpModalContent>
                <div className="alert alert-danger" role="alert">
                  <p>
                    O uso do Certificado Digital modelo A3 será obrigatório em
                    breve. Todas as Entidades Parceiras já devem adquirir e/ou
                    configurar seus certificados. Fundamentação: RESOLUÇÃO
                    CNPS/MPS Nº 1.358, DE 31 DE AGOSTO DE 2023.
                  </p>
                </div>
              </DtpModal.DtpModalContent>
              <DtpModal.DtpModalFooter>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <DtpButton
                    id="btnNaoAutorizo"
                    title="Voltar"
                    className="pull-left dtp-secondary"
                    onClick={voltar}
                    accessibilityName="Voltar"
                    aria-label="Voltar"
                    hint="Voltar"
                  />
                  <DtpButton
                    id="btnAutorizo"
                    title="Ok"
                    className="pull-right dtp-primary"
                    onClick={avancar}
                    accessibilityName="Ok"
                    aria-label="OK"
                    hint="OK"
                  />
                </div>
              </DtpModal.DtpModalFooter>
            </DtpModal>
          </DtpContentAdmin>
        </DtpAdmin>
      </div>
    </>
  );
};

export default AvisoCertificadoDigital;
