import React from 'react';

class DtpContentAdmin extends React.Component {

  render() {
    return (
      <div className="dtp-content-admin">
        {this.props.children}
      </div>
    )
  }
}

export default DtpContentAdmin;