/* eslint-disable */
const TK_CATEGORY_SERVICE_SELECT = 'PAT-SELECAO-SERVICO';
const TK_CATEGORY_DASHBOARD = 'PAT-ACESSO-DASHBOARD';
const TK_CATEGORY_PDF_CONSOLIDADO = 'PAT-PDF-CONSOLIDADO';

export const matomoAsyncLoader = () => {
    if(process.env.REACT_APP_CODIGO && process.env.REACT_APP_CODIGO === 'prod') {
        setTimeout(matomoLoader, 500)
    } 
};

export const matomoLoader  = () => {
    window._paq=window._paq?window._paq:[];
    let p = _paq ? _paq : window._paq;

    p.push(['trackPageView']);
    p.push(['enableLinkTracking']);
    (function() {
        let u="https://ew.dataprev.gov.br/";
        p.push(['setTrackerUrl', u+'matomo.php']);
        p.push(['setSiteId', '31']);
        let d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.type='text/javascript';
        g.async=true;
        g.defer=true;
        g.src=u+'matomo.js';
        s.parentNode.insertBefore(g,s);
    })();

    window.baseTrackEvent = (category, action,name,value) =>{
        let trackedEvent = ['trackEvent',category];
        if( action){
            trackedEvent.push(action);
            if(name && name != ""){
                trackedEvent.push(name);
                if(value){
                    trackedEvent.push(value)
                }
            }
        }
        
        let p=window._paq?window._paq:[];
        p.push(trackedEvent)
    };
    
    /* Monitora quantidade de acessos a seleção de serviços do fluxo de requerimento (sag-spa)*/
    window.trackServiceSelect = (action,name,value) => {
        window.baseTrackEvent(TK_CATEGORY_SERVICE_SELECT, action, name, value) 
    }

    /* Monitora quantidade de acessos ao dashboard (get-spa) */
    window.trackAcessoDashboardTarefas = (action,name,value) => {
        window.baseTrackEvent(TK_CATEGORY_DASHBOARD, action, name, value)
    }

    /* Monitora quantidade de acessos ao pdf consolidado (get-spa) */
    window.trackAcessoPdfConsolidadoTarefa = (action,name,value) => {
        window.baseTrackEvent(TK_CATEGORY_PDF_CONSOLIDADO, action, name, value)
    }
};
