import React from 'react';
import Falha from './features/home/Falha';
import { BACKEND_INDEX } from './common/constants';

import { getToken } from './common/AuthManager';
import SimpleLoading from 'components/loading/SimpleLoading';

let scriptId = null;
class MicroFront extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      falha: false
    };
    
    this.capitalize = this.capitalize.bind(this);
  }

  componentDidMount() {
    
    const { name, host, document, refresh = false } = this.props;
    scriptId = `micro-frontend-script-${name}`;
    const linkId = `micro-frontend-link-${name}`;

    //garante que o microfronend não seja montado desnecessariamente
    if (document.getElementById(scriptId) && !refresh) {
      this.renderMicroFront();
      return;
    }

    fetch(`${host}/asset-manifest.json?t=${new Date().getTime()}`)
      .then(res => {
        return res.json()
      })
      .then(manifest => {
        this.setState({ ...this.state, falha: false })
        
        let loadMain = () => {
          const script = document.createElement('script');
          script.id = scriptId;
          script.crossOrigin = '';
          script.src = `${host}${manifest.files['main.js']}`;
          script.onload = this.renderMicroFront;
          document.head.appendChild(script);
        }

        if (manifest.files['env']) {
          const scriptEnv = document.createElement('script');
          scriptEnv.id = scriptId + "-env";
          scriptEnv.crossOrigin = '';
          scriptEnv.src = `${host}${manifest.files['env']}`;
          scriptEnv.onload = loadMain;
          document.head.appendChild(scriptEnv);
        } else {
          loadMain();
        }

        const link = document.createElement('link');
        link.id = linkId;
        link.href = `${host}${manifest.files['main.css']}`
        link.rel = 'stylesheet';
        document.head.appendChild(link);
      })
      .catch(erro => this.setState({ ...this.state, falha: true }));
  }

  capitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  componentWillUnmount() {
    const { name } = this.props;
    let dtpBlockUi = document.getElementsByClassName('dtp-block-ui')[0];
    if (dtpBlockUi) dtpBlockUi.parentNode.removeChild(dtpBlockUi);

    //garante que o microfronend não seja desmontado desnecessariamente
    // if (history.location.pathname.startsWith(this.props.name, 1)) return;

    let unmountFront = `unmount${this.capitalize(name)}`;
    if (Object.prototype.hasOwnProperty.call(window, unmountFront)) {
      window[unmountFront](`${name}-container`);
    }

    // if (scriptId) {
    //   //remove o script o srcipt micro-frontend-script-?
    //   let element = document.getElementById(scriptId);
    //   let elementLink = document.getElementById(scriptId.replace('script', 'link'));
    //   if (element) {
    //     element.parentNode.removeChild(element);
    //     elementLink.parentNode.removeChild(elementLink);
    //   }

    //   //remove as tags styles do microfront
    //   let style = document.getElementsByTagName('style');
    //   if (style && style.length > 0) {
    //     let size = style.length;
    //     for (let i = 4; i < size; i++) {
    //       style[4].remove();
    //     }
    //   }
    // }
  }

  renderMicroFront = async () => {
    const { name, history } = this.props;
    const token = await getToken();
    window[`render${this.capitalize(name)}`](`${name}-container`, history, { token, api: `${BACKEND_INDEX}` });
  };

  render() {
    if (this.state.falha) {
      return <Falha />
    }
    return <main id={`${this.props.name}-container`} ><SimpleLoading /></main>;
  }
}

MicroFront.defaultProps = {
  document,
  window
};

export default MicroFront;
