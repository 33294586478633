import { padLeft } from "./StringUtils";
import { formatarCPF } from "./formatadores";
import { BACKEND_INDEX } from "./constants";
import axios from "axios";

export const decodificarToken = (token) => {
  if (token === null || token === "undefined") {
    return null;
  }
  const payload = token.split(".")[1];
  if (payload) {
    const decodedData = window.atob(payload);
    return JSON.parse(decodedData);
  } else {
    return null;
  }
};

export const possuiPermissoes = (permissoes) => {
  const data = decodificarToken(localStorage.getItem("ifs_auth"));
  const tokenPermissions = data?.roles ?? [];
  if (typeof permissoes === "string") {
    return tokenPermissions.includes(permissoes);
  } else {
    return intersection(tokenPermissions, permissoes).length > 0;
  }
};

export const intersection = (a, b) => {
  return a.filter((item) => -1 !== b.indexOf(item));
};

export const obterDadosUsuarioLogado = () => {
  const data = decodificarToken(localStorage.getItem("ifs_auth"));
  const dadosRetorno = {
    document: {
      label: "CPF",
      number: formatarCPF(data.cpf),
    },
    firstName: data.nome,
    information: getInformation(data.geridRoles),
  };
  return dadosRetorno;
};

const getInformation = (geridRoles) => {
  if (!geridRoles) {
    return "Nenhum informação do usuário";
  }
  let infos = geridRoles[0]?.split("#");
  if (infos) {
    return infos.join(" ");
  } else {
    return "";
  }
};

export const obterCpfNomeUsuarioLogado = () => {
  const data = decodificarToken(localStorage.getItem("ifs_auth"));
  const dadosRetorno = {
    nome: data.nome,
    cpf: data.cpf,
  };
  return dadosRetorno;
};

export const obterUsuarioLogado = () => {
  const data = decodificarToken(localStorage.getItem("ifs_auth"));
  return padLeft(data.cpf, "0");
};

export const obterCNPJEntidadeConveniada = () => {
  const data = decodificarToken(localStorage.getItem("ifs_auth"));
  return data.domains[0].replace("CNPJ:", "").replace(".CNPJ", "");
};

export const getToken = async () => {
  return {
    ifsAuth: localStorage.getItem("ifs_auth"),
    ifsTs: localStorage.getItem("ifs_ts"),
  };
};

export const isUsuarioLogado = () => {
  const atual = new Date();
  
  const token = localStorage.getItem("ifs_auth");
  let timeToExpire = localStorage.getItem("ifs_ts");

  const data = decodificarToken(token);
  if (!data || data.client_id !== "SAG") {
    return false;
  }

  if (timeToExpire) {
    timeToExpire = new Date(timeToExpire);

    if (token) {
      const geracaoToken = localStorage.getItem("ifs_iat");
      if (geracaoToken) {
        const dataGeracaoToken = new Date(geracaoToken)
        const limitePatMilisegundos = 2 * 60 * 60 * 1000; //2 horas
        if (atual - dataGeracaoToken > limitePatMilisegundos) {
          //Passou de 2h (deslogar usuario)
          unStoreToken();
          return false;
        }
      }
      const timeout = localStorage.getItem("ifs_timout");
      if (timeout) {
        clearTimeout(timeout);
      }
      renovarTokenJWT();
      const tokenExpireTime = new Date(data.exp * 1000);
      if (tokenExpireTime > atual) {
        return true;
      } else if (timeToExpire > atual) {
        return true;
      } else if (timeToExpire < atual) {
        unStoreToken();
      }
    }
  }
  return false;
};

export const unStoreToken = () => {
  Object.keys(localStorage)
    .filter(chavesQueDevemSerRemovidasNoLogout)
    .forEach((chave) => localStorage.removeItem(chave));
};

const chavesQueDevemSerRemovidasNoLogout = (chave) =>
  chave.endsWith("_ips") ||
  chave.endsWith("_at") ||
  chave.endsWith("_auth") ||
  chave.endsWith("_ts") ||
  chave.endsWith("token") ||
  chave.endsWith("idPapelSelecionado") ||
  chave.endsWith("ifs_timout");

const textoUrl = () => {
  let vars = [];
  const queryString = {};
  let query = document.location.hash;
  if (query.search("#") !== -1) {
    query = query.split("#");
    vars = query[1].split("&");
  } else {
    vars = query.split("&");
  }
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (typeof queryString[pair[0]] === "undefined") {
      queryString[pair[0]] = decodeURIComponent(pair[1]);
    } else if (typeof queryString[pair[0]] === "string") {
      const arr = [queryString[pair[0]], decodeURIComponent(pair[1])];
      queryString[pair[0]] = arr;
    } else {
      queryString[pair[0]].push(decodeURIComponent(pair[1]));
    }
  }
  return queryString;
};

export const obterTokenJWT = async () => {
  localStorage.removeItem("access_token");

  const texto = textoUrl();

  const time = new Date();
  time.setSeconds(texto.expires_in);

  localStorage.setItem("ifs_ts", time);
  localStorage.setItem("access_token", texto.access_token);
  localStorage.setItem("ifs_at", texto.access_token);
  localStorage.setItem("ifs_ei", texto.expires_in);
  
  const uri =
    BACKEND_INDEX +
    "gestaoApi/gerid/oauth" +
    `?accessToken=${texto.access_token}`;
  const response = await axios.get(uri);
  localStorage.setItem("gerid_token", response.data);
};

export const obterTokenJWTFinal = async (idPapelSelecionado, accessToken) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("gerid_token");
  localStorage.removeItem("idPapelSelecionado");

  const uri =
    BACKEND_INDEX +
    "gestaoApi/gerid/oauth" +
    `?accessToken=${accessToken}` +
    `&idPapelSelecionado=${idPapelSelecionado}`;

  const response = await axios.get(uri);

  localStorage.setItem("ifs_auth", response.data);
  localStorage.setItem("ifs_iat", new Date());
};

const renovarTokenJWT = async () => {
  const token = localStorage.getItem("ifs_auth");
  const data = decodificarToken(token);
  const tokenExpireTime = new Date(data.exp * 1000);
  const atual = new Date();
  if (token && tokenExpireTime < atual) {
    const uri =
      BACKEND_INDEX +
      "gestaoApi/gerid/oauth" +
      `?accessToken=${localStorage.getItem("ifs_at")}` +
      `&idPapelSelecionado=${localStorage.getItem("ifs_ips")}`;
    const response = await axios.get(uri);
    localStorage.setItem("ifs_auth", response.data);
    localStorage.setItem("ifs_iat", new Date());
  }
  refreshToken();
};

const refreshToken = () => {
  const atual = new Date();
  let timeToExpire = localStorage.getItem("ifs_ts");
  if (timeToExpire <= atual) {
    unStoreToken();
  } else {
    const timeout = setTimeout(renovarTokenJWT, 45000);
    localStorage.setItem("ifs_timout", timeout);
  }
};
