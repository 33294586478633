import React from 'react';
import { Link } from 'react-router-dom';

class DtpBreadcrumb extends React.Component {
  render() {
    let { pathname } = this.props.location;
    
    return (
      <div className='dtp-breadcrumb'>{this.getRenderItens(pathname)}</div>
    );
  }

  getRenderItens(pathname) {
    let itens = pathname.split('/');

    let itensFiltrados = itens.filter((el) => el !== null && isNaN(el));
    let retorno = itensFiltrados.map((element, index) => {
      if (index === itensFiltrados.length - 1) return <span>{this.getDescricao(element)}</span>;
      else
        return (
          <React.Fragment>
            <Link to={`/${element}`}>{this.getDescricao(element)}</Link>
            <span className='dtp-breadcrumb-separator'>></span>
          </React.Fragment>
        );
    });

    retorno.unshift(
      <React.Fragment>
        <Link to='/'>
          <i class='fas fa-home'></i>
        </Link>
        {retorno.length > 0 ? (
          <span className='dtp-breadcrumb-separator'>></span>
        ) : (
          ''
        )}
      </React.Fragment>
    );
    return retorno;
  }

  getDescricao(target) {
    let composto = target.split('_');

    return composto.map(el => this.capitalize(el)).join(' ');
  }

  capitalize = word => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }
}

export default DtpBreadcrumb;
