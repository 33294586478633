import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classnames from "classnames";

const DtpBlockUi = props => {
  const { blocking, unblockOnESC, onUnblock, showSpinner, message } = props;

  const handleKeydown = event => {
    if (blocking && unblockOnESC && event.keyCode === 27) {
      onUnblock();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown, true);
    return () => {
      window.removeEventListener("keydown", handleKeydown, true);
    };
  });

  const dtpBlockUiFocus = useRef(null);

  useEffect(() => {
    dtpBlockUiFocus.current.focus({preventScroll:true});
  });

  const classes = classnames("dtp-block-ui", { blocked: blocking }, { unblocked: !blocking });
  const escInformation = unblockOnESC ? " Use a tecla ESC para desbloquear a tela!" : "";
  const msg = blocking ? "Tela de bloqueio ativada. Aguarde!" + escInformation : "Tela desbloqueada";

  const componentBody = (
    <div className={classes} aria-live="polite" aria-label={msg}>
      <input ref={dtpBlockUiFocus} aria-hidden="true" className="sr-only" />
      {blocking && (
        <div className="loader">
          {showSpinner && (
            <i
              aria-label="Ícone spinner girando"
              className="icon rotating ico-spinner"
            />
          )}
          <span className="label">{message}</span>
        </div>
      )}
    </div>
  );

  return ReactDOM.createPortal(componentBody, document.body);
};

DtpBlockUi.propTypes = {
  /** Controla a exibição da tela de bloqueio */
  blocking: PropTypes.bool.isRequired,

  /** Define se a tela de bloqueio deve ser fechada ao teclar ESC */
  unblockOnESC: PropTypes.bool.isRequired,

  /** Callback executado ao desbloquear a tela */
  onUnblock: PropTypes.func,

  /** Define se será exibido o ícone de spinner girando ao bloquear a tela */
  showSpinner: PropTypes.bool.isRequired,

  /** Mensagem que será exibida no centro da tela de bloqueio */
  message: PropTypes.string
};

DtpBlockUi.defaultProps = {
  blocking: false,
  unblockOnESC: true,
  showSpinner: true,
  message: "Aguarde...",
  onUnblock: () => { }
};

export default DtpBlockUi;
