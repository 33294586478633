import * as types from './types';

const INITIAL_STATE = {
  loader: false,
  mensagem: null,
  userData: null,
  loadingPage: false,
  textoAssinatura: ''
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.HOME_LOADING:
      return { ...state, mensagem: null, loader: true }
    case types.ASSINATURA_ATUALIZAR_TEXTO:
        return { ...state, texto: action.payload };
    default:
      return { ...state }
  }
}
