export const HOME_LOADING = 'HOME_LOADING'
export const GET_HOME_ERROR_MESSAGE = 'GET_HOME_ERROR_MESSAGE';
export const GET_HOME_ALERT_MESSAGE = 'GET_HOME_ALERT_MESSAGE';
export const GET_HOME_INFO_MESSAGE = 'GET_HOME_INFO_MESSAGE';
export const GET_HOME_SUCCESS_MESSAGE = 'GET_HOME_SUCCESS_MESSAGE';
export const CONCLUIR_ASSINATURA = 'CONCLUIR_ASSINATURA';

export const DESPACHO_OBTER_ASSINATURA_SUCESSO = 'DESPACHO_OBTER_ASSINATURA_SUCESSO';
export const DESPACHO_OBTER_ASSINATURA_ERRO = 'DESPACHO_OBTER_ASSINATURA_ERRO';

export const ASSINATURA_ATUALIZAR_TEXTO = 'ASSINATURA_ATUALIZAR_TEXTO';