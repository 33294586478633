import React from 'react';

const Falha = () => (
  <div>
    Infelizmente o serviço não está disponível no momento
  </div>
)

export default Falha;

