import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classnames from 'classnames';
import {
  LAST_MESSAGES_ELAPSED_TIME,
  LAST_MESSAGES_LENGTH
} from 'common/constants';

export const MESSAGE_TYPES = ['danger', 'info', 'warning', 'success'];

class DtpSnackbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: []
    };
    if (LAST_MESSAGES_LENGTH && LAST_MESSAGES_LENGTH > 0) {
      this.state = {
        ...this.state,
        lastMessages: []
      };
    }

    this.arrayObjectIndexOf = this.arrayObjectIndexOf.bind(this);
    this.verifyNewMessage = this.verifyNewMessage.bind(this);
    this.duplicatedMessage = this.duplicatedMessage.bind(this);

    window.DtpSnackbar = this;
  }

  arrayObjectIndexOf(myArray, searchTerm, property) {
    for (var i = 0, len = myArray.length; i < len; i++) {
      if (myArray[i][property] === searchTerm) return i;
    }
    return -1;
  }

  duplicatedMessage(message) {
    if (this.state.lastMessages) {
      /*
       * Remove as mensagens que já estão na 'fila' há mais
       * tempo que 'LAST_MESSAGES_ELAPSED_TIME'
       */
      while (
        this.state.lastMessages.length > 0 &&
        Date.now() - this.state.lastMessages[0].insertedTime >
          LAST_MESSAGES_ELAPSED_TIME
      ) {
        this.state.lastMessages.shift();
      }
      /*
       * Compara a nova mensagem ('message') com as já existentes
       * em 'this.state.lastMessages', caso esta não esteja vazia
       */
      if (this.state.lastMessages.length > 0) {
        for (var index = 0; index < this.state.lastMessages.length; index++) {
          if (this.state.lastMessages[index].text === message.text) {
            return true;
          }
        }
      }
    }
    /*
     * Armazena a mensagem ('message') nas últimas mensagens ('this.state.lastMessages')
     * para comparar com a próxima mensagem ('message'). O propósito de evitar a exibição
     * de mensagens repetidas. Essa checagem somente ocorrerá caso 'LAST_MESSAGES_LENGTH'
     * tenha sido informado e seu valor seja maior que '0' (zero).
     */
    if (this.state.lastMessages) {
      if (this.state.lastMessages.length === LAST_MESSAGES_LENGTH) {
        this.state.lastMessages.shift();
      }
      this.state.lastMessages.push({
        text: message.text,
        insertedTime: Date.now()
      });
    }
    return false;
  }

  addMessage(message) {
    if (!this.duplicatedMessage(message)) {
      const messages = update(this.state.messages, { $push: [message] });
      this.setState({ messages: messages });

      if (this.state.message === undefined) {
        this.setState({ message: message });
      }

      if (!this.verifyNewMessageTimer) {
        this.verifyNewMessageTimer = setInterval(this.verifyNewMessage, 100);
      }
    }
  }

  verifyNewMessage() {
    if (this.state.message === undefined) {
      if (this.state.messages.length > 0) {
        let message = this.state.messages[0];
        this.setState({ message: message });
      } else {
        this.verifyNewMessageTimer = undefined;
      }
    }
  }

  removeMessage(message) {
    let index = this.arrayObjectIndexOf(
      this.state.messages,
      message.index,
      message.index
    );

    var messages = update(this.state.messages, {
      $splice: [[index, 1]]
    });

    this.setState({ messages: messages, message: undefined });
  }

  render() {
    const msg = this.state.message;
    return (
      <div
        className={classnames('dtp-snackbar', {
          'dtp-snackbar-no-messages': this.state.messages.length === 0
        })}
      >
        {msg && msg !== undefined ? (
          <DtpSnackbarMessage
            message={msg}
            onClose={() => this.removeMessage(msg)}
            timeout={this.state.message.timeout}
            qtdRemainingMessages={this.state.messages.length - 1}
          />
        ) : null}
      </div>
    );
  }
}

DtpSnackbar.propTypes = {
  message: PropTypes.shape({
    /** Tipo da mensagem */
    type: PropTypes.oneOf(MESSAGE_TYPES),

    /** Título da mensagem. */
    title: PropTypes.string,

    /** Texto da mensagem. */
    text: PropTypes.string,

    /** Configura quanto tempo a mensagem fica em exibição. Deve ser informado em milissegundos. */
    timeout: PropTypes.number,

    /** Configura se a mensagem deve desaparecer automaticamente ou não. */
    dismissOnTimeout: PropTypes.bool
  })
};

DtpSnackbar.defaultProps = {
  message: {
    timeout: 7000,
    dismissOnTimeout: true
  }
};

export default DtpSnackbar;

export class DtpSnackbarMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opacity: 0
    };

    this.effectDuration = 300;
    this.doClose = this.doClose.bind(this);
    this.doOpen = this.doOpen.bind(this);
  }

  componentDidMount() {
    this.openTimer = setInterval(this.doOpen, this.effectDuration);
  }

  componentWillUnmount() {
    clearInterval(this.remainingTimer);
    clearInterval(this.closeTimer);
  }

  doOpen() {
    clearInterval(this.openTimer);
    this.setState({ opacity: 1 });
    if (
      typeof this.props.message.dismissOnTimeout === 'undefined' ||
      this.props.message.dismissOnTimeout === true
    ) {
      this.remainingTimer = setInterval(this.doClose, this.props.timeout);
    }
  }

  doClose() {
    this.setState({ opacity: 0 });
    this.props.onClose();
  }

  alertClass(type) {
    let classes = {
      danger: 'danger',
      warning: 'warning',
      info: 'info',
      success: 'success'
    };
    return classes[type] || classes.success;
  }

  alertIcon(type) {
    let icons = {
      danger: 'fas fa-times-circle',
      warning: 'fas fa-exclamation-triangle',
      info: 'fas fa-info-circle',
      success: 'fas fa-check-circle'
    };
    return icons[type] || icons.success;
  }

  alertButtonIcon(type) {
    let buttonTexts = {
      danger: 'fas fa-times',
      warning: 'fas fa-times',
      info: 'fas fa-times',
      success: 'fas fa-times'
    };
    return buttonTexts[type] || buttonTexts.success;
  }

  render() {
    const message = this.props.message;

    const defaultStyle = {
      transition: `opacity ${this.effectDuration}ms ease-in-out`,
      opacity: this.state.opacity
    };

    return (
      <div
        className={classnames(
          'dtp-snackbar-message',
          this.alertClass(message.type)
        )}
        style={defaultStyle}
      >
        <span className="label">{this.props.qtdRemainingMessages}</span>
        <i
          className={classnames(
            'dtp-snackbar-message-icon',
            this.alertIcon(message.type),
            this.alertClass(message.type)
          )}
          aria-hidden="true"
        ></i>
        <span className="dtp-snackbar-message-text">
          {message.text}
          <br />
        </span>
        <button
          ref={ref => (this.linkRef = ref)}
          className={classnames(
            'dtp-snackbar-message-link',
            this.alertClass(message.type)
          )}
          onClick={() => this.doClose()}
        >
          {/* {this.alertButtonText(message.type)} */}
          <i className={this.alertButtonIcon(message.type)}></i>
        </button>
      </div>
    );
  }
}

DtpSnackbarMessage.propTypes = {
  /** Função que é executada quando a mensagem é fechada */
  onClose: PropTypes.func.isRequired,

  /** Tempo de exibição da mensagem */
  timeout: PropTypes.number,

  /** Objeto contendo os dados da mensagem */
  message: PropTypes.shape({
    /** Texto da mensagem */
    text: PropTypes.string.isRequired,

    /** Tipo da Mensagem */
    type: PropTypes.oneOf(MESSAGE_TYPES)
  }).isRequired,

  /** Atributo que define se a mensagem deve ser fechada ao passar o tempo definido no atributo timeout */
  dismissOnTimeout: PropTypes.bool,

  /** Atributo que define quantas mensagens ainda faltam ser exibidas */
  qtdRemainingMessages: PropTypes.number.isRequired,

  /** Atributo que define o tamanho da fila de verificação de mensagens duplicadas ('this.state.lastMessages') */
  lastMessagesLength: PropTypes.number
};

DtpSnackbarMessage.defaultProps = {
  timeout: 7000,
  dismissOnTimeout: true,
  qtdRemainingMessages: 0
};
