import React from "react";
import { Provider } from "react-redux";

import store from "./store";

import {
  obterDadosUsuarioLogado,
  isUsuarioLogado,
  unStoreToken,
  obterTokenJWT,
  decodificarToken,
  obterTokenJWTFinal
} from "./common/AuthManager";
import App from "./features/App";
import {
  FRONTEND_INDEX,
  AUTH_PATH,
  AUTH_PATH_LOGOUT_OUT
} from "./common/constants";
import Loading from "./components/loading/Loading";

import TelaSelecaoAbrangencia from "./components/TelaSelecaoAbrangencia";
import ErrorPage from "./components/ErrorPage";

const root = () => {
  // usuario deve estar logado com token válido.
  if (isUsuarioLogado()) {
    let ifsAuth = decodificarToken(localStorage.getItem("ifs_auth"));
    if (!ifsAuth?.geridRoles?.length) {
      if (localStorage.getItem("gerid_token")) {
        const geridToken = decodificarToken(localStorage.getItem("gerid_token"));
        if (geridToken?.geridRoles?.length) {
          localStorage.setItem("ifs_auth", localStorage.getItem("gerid_token"))
        }
      }
      ifsAuth = decodificarToken(localStorage.getItem("ifs_auth"));
    } 
    if (!ifsAuth?.geridRoles?.length) {
      setTimeout(function () {
        window.open(AUTH_PATH, "_self");
      }, 1000);
    }
    else {
      store.getState().homeData.userData = obterDadosUsuarioLogado();
      return (
        <Provider store={store}>
          <App />
        </Provider>
      );
    }
  }

  if (
    document.URL.search("access_denied") !== -1 ||
    document.location.hash.search("access_denied") !== -1
  ) {
    unStoreToken();
    window.open(AUTH_PATH_LOGOUT_OUT, "_self");
    return <Loading />;
  }

  if (
    document.URL.search("access_token") !== -1 ||
    document.location.hash.search("access_token") !== -1
  ) {
    obterTokenJWT();

    setTimeout(function () {
      window.open(FRONTEND_INDEX, "_self");
    }, 3000);
    return <Loading />;
  }

  if (localStorage.getItem("gerid_token")) {

    const token = localStorage.getItem("gerid_token");

    const data = decodificarToken(token);

    const domains = data.domains;

    const rawRoles = data.rawRoles;

    localStorage.removeItem("gerid_token");

    return <TelaSelecaoAbrangencia domains={domains} rawRoles={rawRoles} />;
  }

  if (localStorage.getItem("idPapelSelecionado") && localStorage.getItem("access_token")) {

    const accessToken = localStorage.getItem("access_token");
    const idPapelSelecionado = localStorage.getItem("idPapelSelecionado");
    
    localStorage.removeItem("idPapelSelecionado");   

    obterTokenJWTFinal(idPapelSelecionado, accessToken);

    setTimeout(function () {
      window.open(FRONTEND_INDEX, "_self");
    }, 3000);

    return <Loading />;
  }

  if (localStorage.getItem("access_token")) {
    unStoreToken();
    return <ErrorPage />;
  }

  if (!localStorage.getItem("access_token") && localStorage.getItem("idPapelSelecionado")) {
    unStoreToken();
    return <ErrorPage />;
  }

  setTimeout(function () {
    window.open(AUTH_PATH, "_self");
  }, 1000);
  return <Loading />;
};

export default root;
