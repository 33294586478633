import { padLeft } from './StringUtils'

const MASCARA_TEL_CELULAR = "(##) #####-####";
const MASCARA_TEL_FIXO = "(##) ####-####";

export const formatCNPJ = (cnpj) => {
  if (!cnpj) {
    return ''
  }
  return padLeft(cnpj, '0', 14).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
}

export function format(mask, value) {
  if (value) {
    let boleanoMascara;
    let posicaoCampo = 0;
    let NovoValorCampo = '';
    const exp = '/\\-|\\.|\\/|\\(|\\)| /g';
    const campoSoNumeros = value.replace(exp, '');
    let TamanhoMascara = campoSoNumeros.length;

    for (let i = 0; i <= TamanhoMascara; i++) {
      boleanoMascara = ((mask.charAt(i) === '-') || (mask.charAt(i) === '.') || (mask.charAt(i) === '/'));
      boleanoMascara = boleanoMascara || ((mask.charAt(i) === '(') || (mask.charAt(i) === ')') || (mask.charAt(i) === ' '));

      if (boleanoMascara) {
        NovoValorCampo += mask.charAt(i);
        TamanhoMascara++;
      } else {
        NovoValorCampo += campoSoNumeros.charAt(posicaoCampo);
        posicaoCampo++;
      }
    }

    return NovoValorCampo;
  }
  return '';
}

export const formatarCPF = (numeroCpf) => {

  if (numeroCpf) {
    let cpfSoNumeros = String(numeroCpf).replace(/\D/g, "");
    let strCpf = padLeft(cpfSoNumeros, '0', 11);
    let cpfFormatado = strCpf.slice(-2);
    cpfFormatado = strCpf.slice(-5, -2) + '-' + cpfFormatado;
    cpfFormatado = strCpf.slice(-8, -5) + '.' + cpfFormatado;
    cpfFormatado = strCpf.slice(-11, -8) + '.' + cpfFormatado;
    return cpfFormatado;
  }
  return numeroCpf;
}

export const formatarTelefone = (tel) => {
  if (tel !== undefined) {
    const telSemFormatacao = String(tel).replace(/\D/g, "");
    if (telSemFormatacao.length === 10) {
      return format(MASCARA_TEL_FIXO, telSemFormatacao);
    } else if (telSemFormatacao.length === 11) {
      return format(MASCARA_TEL_CELULAR, telSemFormatacao);
    } else {
      return telSemFormatacao;
    }
  }
}

export const formataNIT = value => {
  value = String(value).replace(/\D/g, "")
  value = String(value).replace(/(\d{3})(\d)/, "$1.$2")
  value = String(value).replace(/(\d{5})(\d)/, "$1.$2")
  value = String(value).replace(/(\d{2})(\d{1,2})$/, "$1-$2")
  return value;
}

/* eslint-disable no-new-wrappers */
export const removeNumeros = value => value ? new String(value).replace(/[0-9]/g, '') : value;

export const removeMask = value => value ? String(value).replace(/[^0-9]/g, '') : value;

export const removeMaskMatricula = value => value ? String(value).replace(/[^0-9X]/g, '') : value

export const apenasAlfanumerico = value => value ? String(value).replace(/\W/g, '') : value;

export const apenasNumero = value => removeMask(value);





