import "./styles.css";
import React, { useState } from "react";
import { FRONTEND_INDEX, AUTH_PATH_LOGOUT_OUT } from "../../common/constants";
import { unStoreToken } from "../../common/AuthManager";
import {
  DtpContent,
  DtpContentContainer,
} from "dtp-react-components";
import SelecaoAbrangencia from "./SelecaoAbrangencia";
import AvisoCertificadoDigital from "./AvisoCertificadoDigital";

function TelaSelecaoAbrangencia(props) {
  const [ehTelaSelecaoAprangencia, setEhTelaSelecaoAprangencia] =
    useState(true);
  const [papelSelecionado, setPapelSelecionado] = useState(null);

  const handlerAutorizo = () => {
    if (papelSelecionado?.id) {
      localStorage.setItem("idPapelSelecionado", papelSelecionado.id);
      localStorage.setItem("ifs_ips", papelSelecionado?.id);
      localStorage.removeItem("");
      window.open(FRONTEND_INDEX, "_self");
    }
  };

  const verificarPapel = () => {
    if (
      papelSelecionado &&
      papelSelecionado.label.indexOf("ENTIDADE_CONVENIADA") !== -1
    ) {
      setEhTelaSelecaoAprangencia(false);
    } else {
      handlerAutorizo();
    }
  };

  const reiniciarTelaDeAbrangencia = () => {
    setEhTelaSelecaoAprangencia(true);
  };

  const handlerNaoAutorizo = () => {
    unStoreToken();
    window.open(AUTH_PATH_LOGOUT_OUT, "_self");
  };

  return (
    <DtpContent>
      <DtpContentContainer>
        {ehTelaSelecaoAprangencia && (
          <SelecaoAbrangencia
            rawRoles={props.rawRoles}
            domains={props.domains}
            voltar={handlerNaoAutorizo}
            avancar={verificarPapel}
            setPapelSelecionado={setPapelSelecionado}
          />
        )}
        {!ehTelaSelecaoAprangencia && <AvisoCertificadoDigital avancar={handlerAutorizo} voltar={reiniciarTelaDeAbrangencia} />}
      </DtpContentContainer>
    </DtpContent>
  );
}

export default TelaSelecaoAbrangencia;
