export const padLeft = (value, padString, length) => {
  let result = value;
  if (result) {
    while (String(result).length < length) result = padString + result;
  }
  return result;
};

export const trunc = (value, length) => {
  return value.substring(0, length);
}


