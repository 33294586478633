import React from 'react';

import './admin.css'

const HEADER_ADMIN = 'headerAdmin';
const BREAD_CRUMB = 'breadcrumbAdmin'
const MENU_ADMIN = 'menuAdmin';
const CONTENT_ADMIN = 'contentAdmin';

class DtpAdmin extends React.Component {

  constructor(props) {
    super(props);

    this.menuAdminRef = React.createRef();

    this.state = {
      isOpen: this.props?.close ? false : true,
      hover: false
    }

    this.openClose = this.openClose.bind(this);
    this.onHover = this.onHover.bind(this);
    this.outHover = this.outHover.bind(this);
  }

  onHover() {
    this.setState(() => {
      if (!this.state.isOpen) {
        const menu = this.menuAdminRef.current;
        menu.style.width = '212px';
        menu.classList.add('dtp-menu-expand');
      }
      return ({ hover: true })
    })
  }

  outHover() {
    this.setState(() => {
      if (!this.state.isOpen) {
        const menu = this.menuAdminRef.current;
        menu.style.width = '80px';
        menu.classList.remove('dtp-menu-expand');
      }
      return ({ hover: false })
    })
  }

  openClose() {
    this.setState({ ...this.state, isOpen: !this.state.isOpen });
  }

  render() {
    let { children } = this.props;
    let headerAdmin = children.filter(child => child.key === HEADER_ADMIN);
    let breadcrumbAdmin = children.filter(child => child.key === BREAD_CRUMB);
    let menuAdmin = children.filter(child => child.key === MENU_ADMIN);
    let contentAdmin = children.filter(child => child.key === CONTENT_ADMIN);

    return (
      <div className="dtp-painel-admin">
        {[React.cloneElement(menuAdmin[0], { ...menuAdmin[0].props, menuAdminRef: this.menuAdminRef, hover: this.state.hover, isOpen: this.state.isOpen, onHover: this.onHover, outHover: this.outHover })]}
        <div className={`dtp-main-admin ${this.state.isOpen ? 'dtp-main-open' : 'dtp-main-close'}`}>
          {[React.cloneElement(headerAdmin[0], { ...headerAdmin[0].props, openClose: this.openClose })]}
          {breadcrumbAdmin}
          {contentAdmin}
        </div>
      </div>
    )
  }

}

export default DtpAdmin;