import React from "react";

import {
  DtpContent,
  DtpContentContainer,
  DtpDataGrid
} from "dtp-react-components";

function ErrorPage(props) {

  return (
    <React.Fragment>

      <DtpContent>
        <DtpContentContainer>
          <div className="row">
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" />
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={{ backgroundColor: 'lightyellow', display: 'flex', justifyContent: 'center' }}>
              <DtpDataGrid title={"LOGIN - PAT"}  >
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <p>
                    Não foi possível autenticar o usuário, tente novamente em alguns instantes.
                    </p>
                  </div>
                </div>
              </DtpDataGrid>
            </div>
          </div>

        </DtpContentContainer>
      </DtpContent>
    </React.Fragment >
  );
  }
export default ErrorPage;
