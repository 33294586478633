const getConfigNewdoo = () => {
  if (process.env.REACT_APP_NEWDOO) {
    switch (process.env.REACT_APP_NEWDOO) {
      case 'local':
        return {
          url: 'http://localhost',
          gateway: '4444'
        };
      case 'dev':
        return {
          url: 'https://dportalspaprx.prevnet'
        };
      case 'homol':
        return {
          url: 'https://hatendimento.inss.gov.br'
        };
      case 'prod':
        return {
          url: 'https://atendimento.inss.gov.br'
        };
      case 'sust':
        return {
          url: 'https://vip-sportalspaprx.inss.gov.br'
        };
      default:
    }
  }
};

const getConfiguracoes = () => {
  if (process.env.REACT_APP_CODIGO) {
    switch (process.env.REACT_APP_CODIGO) {
      case 'local':
        return {
          front: 'http://localhost:8080',
          timerwork: 300000,
          newdoo: getConfigNewdoo(),
          gerid: getConfigGerid()
        };
      case 'dev':
        return {
          front: 'https://dportalspaprx.prevnet',
          timerwork: 60000,
          newdoo: getConfigNewdoo(),
          gerid: getConfigGerid()
        };
      case 'homol':
        return {
          front: 'https://hatendimento.inss.gov.br',
          timerwork: 300000,
          newdoo: getConfigNewdoo(),
          gerid: getConfigGerid()
        };
      case 'prod':
        return {
          front: 'https://atendimento.inss.gov.br',
          timerwork: 1200000,
          newdoo: getConfigNewdoo(),
          gerid: getConfigGerid()
        };
      case 'sust':
        return {
          front: 'https://vip-sportalspaprx.inss.gov.br',
          timerwork: 300000,
          newdoo: getConfigNewdoo(),
          gerid: getConfigGerid()
        };
      default:
    }
  }
};

const getConfigGerid = () => {
  if (process.env.REACT_APP_NEWDOO) {
    switch (process.env.REACT_APP_NEWDOO) {
      case 'local':
        return {
          url: 'https://hgeridinss.dataprev.gov.br',
          redirectUri: 'http://localhost:8080/patCallbackGerid'
        };
      case 'dev':
        return {
          url: 'https://hgeridinss.dataprev.gov.br',
          redirectUri: 'https://dportalspaprx.prevnet'
        };
      case 'homol':
        return {
          url: 'https://hgeridinss.dataprev.gov.br',
          redirectUri: 'https://hatendimento.inss.gov.br'
        };
      case 'prod':
        return {
          url: 'https://geridinss.dataprev.gov.br',
          redirectUri: 'https://atendimento.inss.gov.br'
        };
      case 'sust':
        return {
          url: 'https://hgeridinss.dataprev.gov.br',
          redirectUri: 'https://vip-sportalspaprx.inss.gov.br'
        };
      default:
    }
  }
};

let config = getConfiguracoes();
export const HOST_FRONTEND = config.front;
export const HOST_NEWDOO = config.newdoo.url;
export const HOST_GERID_AUTH = config.gerid.url;
export const GERID_REDIRECT_URI = config.gerid.redirectUri;
export const HOST_NEWDOO_GATEWAY_PORT = config.newdoo.gateway;
export const TIMER_WORK = config.timerwork;

/** CONFIGURAÇÕES GERAIS, NÃO ALTERAR */
export const LOGOUT_USER = 'LOGOUT_USER';
export const FETCH_USER = 'FETCH_USER';
export const BACKEND_INDEX = HOST_NEWDOO_GATEWAY_PORT
  ? `${HOST_NEWDOO}:${HOST_NEWDOO_GATEWAY_PORT}/apis/`
  : `${HOST_NEWDOO}/apis/`;

export const LOGIN_WITH = 'gerid';
export const CLIENT_ID = 'SAG';
export const FRONTEND_INDEX = `${HOST_FRONTEND}/`;

export const AUTH_PATH = `${HOST_GERID_AUTH}/cas/oauth2.0/authorize?client_id=${CLIENT_ID}&response_type=token&redirect_uri=${GERID_REDIRECT_URI}`;
export const AUTH_PATH_LOGOUT = `${HOST_GERID_AUTH}/auth/logoutConfirmation??client_id=${CLIENT_ID}&redirect_uri=${AUTH_PATH}`;

export const AUTH_PATH_LOGOUT_OUT = `${HOST_GERID_AUTH}/cas/logout?redirect_uri=${FRONTEND_INDEX}`;

export const TIMEOUT_MESSAGE = 5000;

export const LAST_MESSAGES_ELAPSED_TIME = 10000;

export const LAST_MESSAGES_LENGTH = 3;