import { combineReducers } from 'redux';

import homeReducer from './features/home/reducer';

import { LOGOUT_USER } from './common/constants';

const INITIAL_STATE = undefined;

const appReducers = combineReducers({
  homeData: homeReducer
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return INITIAL_STATE;
    default:
      return appReducers(state, action);
  }
};

export default rootReducer;