import React from "react";

class DtpHeaderAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: ""
    };
  }

  render() {
    let { appInfo, doLogout, mudarAbrangencia } = this.props;
    return (
      <header className="dtp-header-admin">
        <div className="left-itens">
          <div
            className="link"
            onClick={this.props.openClose}
            aria-label="sidebar"
          >
            <i aria-hidden="true" class="fas fa-bars"></i>
          </div>
          <span>{appInfo.firstName}</span>
        </div>
        <div className="right-itens">
          <div
            className="link"
            onClick={doLogout}
            style={{ paddingTop: "12px" }}
          >
            <span class="dtp-container-tooltip" data-tooltip="Sair">
              <i aria-label="Sair" class="fas fa-sign-out-alt"></i>
            </span>
          </div>
        </div>
        <div className="right-itens">
          <div
            className="link"
            onClick={mudarAbrangencia}
            style={{ paddingTop: "12px" }}
          >
            <span
              class="dtp-container-tooltip"
              data-tooltip="Mudar abrangência"
            >
              <i aria-label="Mudar abrangência" class="fas fa-store-alt"></i>
            </span>
          </div>
        </div>
      </header>
    );
  }
}

export default DtpHeaderAdmin;
