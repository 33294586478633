import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import {
  DtpButton,
  DtpFormGroup,
  DtpControlLabel,
  DtpModal,
} from "dtp-react-components";
import DtpAdmin from "components/DtpAdmin/DtpAdmin";
import DtpHeaderAdmin from "components/DtpHeaderAdmin/DtpHeaderAdmin";
import DtpMenuAdmin from "components/DtpMenuAdmin/DtpMenuAdmin";
import DtpContentAdmin from "components/DtpContentAdmin/DtpContentAdmin";

const SelecaoAbrangencia = ({
  rawRoles,
  domains,
  avancar,
  voltar,
  setPapelSelecionado,
}) => {
  const [abrangencia, setAbrangencia] = useState(null);
  const [papel, setPapel] = useState(null);
  const [papeis, setPapeis] = useState([]);

  useEffect(() => {
    const selectedRules = rawRoles.filter(
      (rawRole) =>
        rawRole.domainType.name + ":" + rawRole.domain.name === abrangencia
    );

    const papeisRule = selectedRules.map((p) => {
      return { value: p.id, label: p.name };
    });
    setPapeis(papeisRule);
  }, [abrangencia, rawRoles]);

  useLayoutEffect(() => {
    document
      .querySelectorAll(".title-container button")
      .forEach((e) => e.remove());
  }, [rawRoles, domains, papeis]);

  const abrangencias = useMemo(
    () =>
      domains.map((a) => ({
        value: a,
        label: a,
      })),
    [domains]
  );

  const selecionarPapel = (value) => {
    setPapel(value);
    let papelSelecionadoSelect = papeis.find(
      (selected) => selected.value === value
    );
    setPapelSelecionado({
      id: papelSelecionadoSelect?.value,
      label: papelSelecionadoSelect?.label,
    });
  };

  const verificarPapel = () => {
    avancar();
  };

  return (
    <>
      <div className="selecao-abrangencia">
        <DtpAdmin close={true}>
          <DtpHeaderAdmin
            key="headerAdmin"
            appInfo={{
              firstName: "Portal de Atendimento | INSS",
              lastName: "",
              owner: "11.11.2022",
            }}
            doLogout={voltar}
            mudarAbrangencia={() => null}
          ></DtpHeaderAdmin>
          <DtpMenuAdmin
            key="menuAdmin"
            version={"_"}
            userData={{
              firstName: "",
              document: {
                label: "",
                number: "",
              },
              information: "",
              roles: [],
            }}
          />
          <DtpContentAdmin key="contentAdmin">
            <DtpModal
              id={"modal"}
              show={true}
              title={"LOGIN - PAT"}
              showTitle={true}
              onHide={() => null}
              size={"md"}
            >
              <DtpModal.DtpModalContent>
                <p>
                  A aplicação <strong>PAT</strong> solicita acesso às seguintes
                  informações sobre você:
                </p>
                <div>
                  <ul>
                    <li>Nome completo</li>
                    <li>Suas permissões de acesso</li>
                    <li>CPF</li>
                    <li>Abrangência</li>
                    <li>Papel</li>
                    <li>Matrícula do usuário</li>
                    <li>
                      Lista de <em>Organization Unities</em> em seu cadastro no
                      LDAP
                    </li>
                  </ul>
                </div>
                <div>
                  <DtpFormGroup controlId="dtpSelectAbrangencia">
                    <DtpControlLabel animate={false} label="Abrangência" />
                    <select
                      value={abrangencia}
                      className="selecao-pat"
                      onChange={(e) => setAbrangencia(e.target.value)}
                      aria-label="Selecione a Abrangência"
                      id="dtpSelectAbrangencia"
                    >
                      <option value="">Selecione uma Opção</option>
                      {abrangencias
                        ?.sort((a, b) =>
                          a?.label && b?.label && a.label > b.label ? 1 : -1
                        )
                        ?.map((p) => (
                          <option value={p.value}>{p.label}</option>
                        ))}
                    </select>
                  </DtpFormGroup>
                </div>
                <div>
                  <DtpFormGroup
                    controlId="dtpSelectPapel"
                    aria-label={
                      papeis?.length
                        ? "Selecione um Papel"
                        : "Selecione primeiro uma abrangência"
                    }
                  >
                    <DtpControlLabel animate={false} label="Papel" />
                    <select
                      value={papel}
                      className="selecao-pat"
                      onChange={(e) => selecionarPapel(e.target.value)}
                      id="dtpSelectPapel"
                    >
                      <option value="">Selecione uma Opção</option>
                      {papeis
                        ?.sort((a, b) =>
                          a?.label && b?.label && a.label > b.label ? 1 : -1
                        )
                        ?.map((p) => (
                          <option value={p.value}>{p.label}</option>
                        ))}
                    </select>
                  </DtpFormGroup>
                </div>
              </DtpModal.DtpModalContent>
              <DtpModal.DtpModalFooter>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <DtpButton
                    id="btnNaoAutorizo"
                    title="Não Autorizo"
                    className="pull-left dtp-secondary"
                    onClick={voltar}
                    accessibilityName="Não Autorizo"
                    aria-label="Não Autorizo"
                    hint="Não Autorizo"
                  />
                  <DtpButton
                    id="btnAutorizo"
                    disabled={!papel}
                    title="Autorizo"
                    className="pull-right dtp-primary"
                    onClick={() => verificarPapel()}
                    accessibilityName="Autorizo"
                    aria-label="Autorizo"
                    hint="Autorizo"
                  />
                </div>
              </DtpModal.DtpModalFooter>
            </DtpModal>
          </DtpContentAdmin>
        </DtpAdmin>
      </div>
    </>
  );
};

export default SelecaoAbrangencia;
